import styled from '@emotion/styled';
import { Box, Divider, Link } from '@mui/material';

export const MainBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #FFFFFF, rgba(255, 255, 255, 0) 60%), url(/images/waterfalls-mist.webp) 60%;
  background-size: cover;
`;

export const FormBox = styled(Box)`
  padding: 0 35px 0 35px;

  .login-button, .register-button {
    width: 100%;
  }

  .login-button {
    margin-top: 30px;
  }

  .register-button {
    margin-top: 10px;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  position: relative;
  float: right;
  margin-top: 10px;
  color: ${ props => props.theme.palette.secondary.main };
`;

export const SSOBox = styled(Box)`
  width: calc(100% - 50px);
  margin-bottom: 40px;
  justify-content: center;
  text-align: center;

  .MuiGrid-container {
    justify-content: center;
  }
`;

export const SSODivider = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
  font: lighter 16px Tungsten;
`;
