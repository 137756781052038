import styled from '@emotion/styled';
import { Box, MobileStepper } from '@mui/material';

export const MainBox = styled(Box)`
  background-color: ${ props => props.theme.palette.primary.main };
`;

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55vh;
  background: url(/images/iguazu-city.webp) no-repeat center bottom;
  background-size: 180% auto;
  clip-path: ellipse(150% 60% at 30% 40%);
`;

export const BottomBox = styled(Box)`
  position: relative;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const TextBox = styled(Box)`
  padding-bottom: 25px;
  max-width: 90%;
  align-items: center;
  justify-content: center;
`;

export const Stepper = styled(MobileStepper)`
  background: none;
  position: relative;

  .MuiMobileStepper-dot {
    background-color: ${ props => props.theme.palette.secondary.dark };;
  }

  .MuiMobileStepper-dotActive {
    background-color: ${ props => props.theme.palette.secondary.light };;
  }
`;
