import React from 'react';
import { Typography } from '@mui/material';
import { MainBox } from './Footer.styles';

const Footer: React.FC<any> = () => {
  return (
    <MainBox>
      <Typography variant='h5' color='white'>
        Desarrollado por CircleMate™
      </Typography>
    </MainBox>
  );
};

export default Footer;
