import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import theme from './globals/theme';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import MinorDenied from './pages/MinorDenied/MinorDenied';
import Introduction1 from './pages/Introduction1/Introduction1';
import Introduction2 from './pages/Introduction2/Introduction2';
import Introduction3 from './pages/Introduction3/Introduction3';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const client = new QueryClient();

root.render(
  <QueryClientProvider client={ client }>
    <CssBaseline />
    <MuiThemeProvider theme={ theme }>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <App /> } />
          <Route path='introduction-1' element={ <Introduction1 /> } />
          <Route path='introduction-2' element={ <Introduction2 /> } />
          <Route path='introduction-3' element={ <Introduction3 /> } />
          <Route path='minor-denied' element={ <MinorDenied /> } />
          <Route path='login' element={ <Login /> } />
          <Route path='register' element={ <Register /> } />
        </Routes>
      </BrowserRouter>
    </MuiThemeProvider>
  </QueryClientProvider>,
);
