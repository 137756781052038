import { MainBox, QuilmesCervezaArgentina, SealPasaporteBlue } from './HeaderAuthentication.styles';

const HeaderAuthentication: React.FC<any> = () => {
  return (
    <MainBox>
      <QuilmesCervezaArgentina />
      <SealPasaporteBlue />
    </MainBox>
  );
};

export default HeaderAuthentication;
