import styled from '@emotion/styled';
import { Box, MobileStepper } from '@mui/material';

export const MainBox = styled(Box)`
  background-color: ${ props => props.theme.palette.primary.main };
`;

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  background: url(/images/beer-and-pizza.webp) no-repeat center;
  background-size: 100% auto;
  clip-path: ellipse(150% 60% at -10% 40%);
`;

export const BottomBox = styled(Box)`
  position: relative;
  height: calc(50vh + 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-top: -100px;
`;

export const SvgBox = styled(Box)`
  padding-bottom: 25px;
  width: 55%;
  align-items: baseline;
  justify-content: space-between;
  display: flex;

  img {
    width: 35px;
  }
`;

export const TextBox = styled(Box)`
  padding-bottom: 25px;
  max-width: 90%;
  align-items: center;
  justify-content: center;
`;

export const Stepper = styled(MobileStepper)`
  background: none;
  position: relative;

  .MuiMobileStepper-dot {
    background-color: ${ props => props.theme.palette.secondary.dark };;
  }

  .MuiMobileStepper-dotActive {
    background-color: ${ props => props.theme.palette.secondary.light };;
  }
`;
