import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';

export const MainBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #FFFFFF, rgba(255, 255, 255, 0) 60%), url(/images/waterfalls-mist.webp) 60%;
  background-size: cover;
`;

export const FormBox = styled(Box)`
  padding: 15px 35px 30px 35px;

  .register-button {
    width: 100%;
  }
`;

export const SSOBox = styled(Box)`
  width: calc(100% - 50px);
  margin-bottom: 40px;
  justify-content: center;
  text-align: center;

  .MuiGrid-container {
    justify-content: center;
  }
`;

export const SSODivider = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
  font: lighter 16px Tungsten;
`;
