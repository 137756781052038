import axios, { AxiosResponse } from 'axios';
import { User } from '../globals/types';

const usersApiUrl = process.env.REACT_APP_API_URL + 'users';

class UserService {

  create(user: User): Promise<AxiosResponse<User>> {
    return axios.post(usersApiUrl, user).then(response => response.data);
  }
}

export default new UserService();
