import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  height: 40vh;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  position: relative;
`;

export const QuilmesCervezaArgentina = styled(Box)`
  width: 200px;
  height: 120px;
  margin-top: 90px;
  align-items: center;
  background: url(/images/quilmes-cerveza-argentina.svg) no-repeat;
  background-size: contain;
`;

export const SealPasaporteBlue = styled(Box)`
  position: absolute;
  width: 145px;
  height: 145px;
  bottom: 0;
  right: -27px;
  background: url(/images/seal-blue-light_blue-blue.svg) no-repeat;
  background-size: contain;
`;
