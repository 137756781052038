import { Box, Typography } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import { BottomBox, MainBox, QuilmesCervezaArgentina, Seal, TextBox, TopBox } from './MinorDenied.styles';

export default function MinorDenied() {
  return (
    <MainBox>
      <TopBox>
        <QuilmesCervezaArgentina />
        <Box>
          <TextBox>
            <Typography variant='h2'>
              Lo sentimos,
              { <br /> }
              Por el momento no podes acceder.
              { <br /> }
              Volve cuando seas mayor de edad.
            </Typography>
          </TextBox>
        </Box>
      </TopBox>
      <BottomBox>
        <Seal />
        <Footer />
      </BottomBox>
    </MainBox>
  );
}
