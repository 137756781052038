import axios, { AxiosResponse } from 'axios';
import { LoggedUser, User } from '../globals/types';

const authApiUrl = process.env.REACT_APP_API_URL + 'auth';

class AuthService {

  login(email: string, password: string): Promise<AxiosResponse<LoggedUser>> {
    return axios.post(authApiUrl, {
      email: email,
      password: password,
    }).then(response => {
      if (response.data.access_token) {
        localStorage.setItem('@pasaportequilmes:user', JSON.stringify(response.data));
        console.log(JSON.stringify(response.data));
      }
      return response;
    });
  }

  logout() {
    axios.post(authApiUrl + '/logout').then(response => {
      localStorage.removeItem('user');
      return response;
    });
  }

  getCurrentUser(): User | null {
    const userStr = localStorage.getItem('@pasaportequilmes:user');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  }
}

export default new AuthService();
