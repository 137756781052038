import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  overflow: hidden;
  background: url(/images/waterfalls-mist.webp);
  background-size: cover;
`;

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

export const QuilmesCervezaArgentina = styled(Box)`
  width: 247px;
  height: 120px;
  margin-top: 70px;
  background: url(/images/quilmes.svg) no-repeat;
  background-size: contain;
`;

export const TextBox = styled(Box)`
  width: 299px;
  margin-top: 20px;
  text-align: center;
  letter-spacing: -0.02em;
`;

export const BottomBox = styled(Box)`
  position: relative;
  height: 35vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top: 2px dotted #ffff;
`;

export const Seal = styled(Box)`
  width: 210px;
  height: 210px;
  position: absolute;
  right: -40px;
  top: -70px;
  background: url(/images/seal-blue-light_blue-blue.svg) no-repeat;
  background-size: cover;
`;
