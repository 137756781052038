import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import { BottomBox, MainBox, Stepper, TextBox, TopBox } from './Introduction2.styles';

export default function Introduction2() {
  return (
    <MainBox>
      <TopBox>
      </TopBox>
      <BottomBox>
        <TextBox>
          <Typography variant='h1' color='white'>
            Encontra tu lugar
          </Typography>
          <Typography variant='h4' color='white'>
            Podras ser capaz de buscar lugares cerca tuyo o cerca de una ubicación específica.
          </Typography>
        </TextBox>
        <Stepper activeStep={ 1 } backButton={ null } nextButton={ null } variant='dots' steps={ 3 } />
        <Button variant='contained' color='secondary' component={ RouterLink } to='/introduction-3'>
          Siguiente
        </Button>
        <Footer />
      </BottomBox>
    </MainBox>
  );
};
