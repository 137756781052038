import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Alert, AlertTitle, Button, Checkbox, FormControlLabel, Grid, Link, TextField } from '@mui/material';
import { User } from '../../globals/types';
import UserService from '../../services/user.service';
import HeaderAuthentication from '../../components/HeaderAuthentication/HeaderAuthentication';
import Footer from '../../components/Footer/Footer';
import { FormBox, MainBox } from './Register.styles';

const schema = yup.object().shape({
  email: yup.string().required('Campo requerido')
    .email('Tienes que introducir un email válido'),
  password: yup.string().required('Campo requerido')
    .min(8, 'Tu contraseña debe tener al menos 8 caracteres')
    .max(25, 'Tu contraseña puede tener como máximo 25 caracteres'),
});

export default function SignUp() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const [apiError, setApiError] = useState<string | null>(null);

  const apiErrorBox = apiError ?
    <Grid item xs={ 12 }>
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        { apiError }
      </Alert>
    </Grid>
    : '';

  const onSubmit = (data: User) => {
    setApiError(null);
    UserService.create(data)
      .then(response => JSON.stringify(response))
      .catch(reason => {
        if (reason.response.data.message === 'User already exists') {
          setApiError('Ya existe un usuario registrado con este correo electrónico');
        }
      });
  };

  return (
    <MainBox>
      <HeaderAuthentication />
      <FormBox component='form' onSubmit={ handleSubmit(onSubmit) }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <TextField
              { ...register('firstName') }
              type='text'
              autoComplete='given-name'
              name='firstName'
              required
              fullWidth
              id='firstName'
              label='Nombre'
              autoFocus
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <TextField
              { ...register('lastName') }
              required
              fullWidth
              id='lastName'
              label='Apellido'
              name='lastName'
              autoComplete='family-name'
            />
          </Grid>
          <Grid item xs={ 3 }>
            <TextField
              { ...register('age') }
              required
              fullWidth
              type='number'
              id='age'
              label='Edad'
              name='age'
              autoComplete='age'
            />
          </Grid>
          <Grid item xs={ 3 }>
            <TextField
              { ...register('gender') }
              required
              fullWidth
              type='text'
              id='gender'
              label='Sexo'
              name='gender'
              autoComplete='gender'
            />
          </Grid>
          <Grid item xs={ 6 }>
            <TextField
              { ...register('country') }
              required
              fullWidth
              id='country'
              label='País'
              name='country'
              autoComplete='country'
            />
          </Grid>
          <Grid item xs={ 6 }>
            <TextField
              { ...register('state') }
              required
              fullWidth
              id='state'
              label='Provincia / Estado'
              name='state'
              autoComplete='state'
            />
          </Grid>
          <Grid item xs={ 6 }>
            <TextField
              { ...register('city') }
              required
              fullWidth
              id='city'
              label='Ciudad'
              name='city'
              autoComplete='city'
            />
          </Grid>
          <Grid item xs={ 12 }>
            <TextField
              { ...register('email') }
              required
              fullWidth
              id='email'
              label='Correo electrónico'
              name='email'
              type='email'
              autoComplete='email'
              helperText={ errors.email?.message }
              error={ !!errors.email?.message }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <TextField
              { ...register('password') }
              required
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='new-password'
              helperText={ errors.password?.message }
              error={ !!errors.password?.message }
            />
          </Grid>
          { apiErrorBox }
          <Grid item xs={ 12 }>
            <FormControlLabel
              control={
                <Checkbox
                  { ...register('newsletter') }
                  color='secondary' />
              }
              label='Deseo recibir notificaciones y promociones'
            />
          </Grid>
          <Grid item xs={ 12 }>
            <Button
              type='submit'
              className='register-button'
              variant='contained'
            >
              Registrarme
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Link component={ RouterLink } to='/login' variant='body2'>
              ¿Ya tenes una cuenta? Iniciar sesión
            </Link>
          </Grid>
        </Grid>
      </FormBox>
      <Footer />
    </MainBox>
  );
}
